<template>
  <div class="search">
    <translation-search></translation-search>
  </div>
</template>

<script>
import translationSearch from "@/components/TranslationSearch.vue";

export default {
  name: 'Search',
  components: {translationSearch},
}
</script>
